<template>
  <div class="survery">
    <div class="introduce">
      <div class="introduce-head">项目简介</div>
      <div
        class="brand-content"
        v-html="content.projectJieShao"
      ></div>
      <div class="introduce-head">硬件设施</div>
      <div
        class="brand-content"
        v-html="content.zhouBianJieShao"
      ></div>
      <div class="introduce-head">开发商属性</div>
      <div class="brand-content">
        <span>开发商：{{content.kaiFaShang ? content.kaiFaShang : '-'}}</span> <span>上市企业： {{content.isShangShi===0?'否':'是'}}</span>
      </div>
      <div class="introduce-head">开发商简介</div>
      <div
        class="brand-content"
        v-html="content.companyJieShao"
      ></div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      content: {}
    }
  },
  mounted () {
    this.axios.post(api.getProjectSurvey, { projectId: this.$route.query.id })
      .then((res) => {
        this.content = res.data.data
        // 清除css样式
        const reg = /style\s*?=\s*?(['"])[\s\S]*?\1/g
        this.content.projectJieShao = this.content.projectJieShao.replace(reg, '')
        this.content.zhouBianJieShao = this.content.zhouBianJieShao.replace(reg, '')
        this.content.companyJieShao = this.content.companyJieShao.replace(reg, '')
      })
  }
}
</script>

<style lang="stylus" scoped>
.survery
  width 1280px
  margin 0px auto
  background-color #272930
  .survery-head
    border-left 2px solid #FFA134
    padding-left 10px
    margin-left 20px
    color #fff
  .introduce
    padding 30px 20px
    .introduce-head
      width 100%
      border-left 2px solid #FFA134
      color #e5e5e5
      font-size 16px
      padding-left 10px
    .brand-content
      padding 15px 0 40px 0
      color #ccc
      line-height 28px
      font-size 14px
      span
        margin-right 25px
</style>
