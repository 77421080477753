<template>
  <div
    class="analysis-wrap"
    v-loading="analysisLoad"
    element-loading-text="加载中..."
  >
    <div
      v-if="!isShowBrandDetail && !analysisLoad"
      class="analysis-wrap-tips"
    >
      <div
        v-if="lookNumber > 0"
        class="look-detail-wrap"
      >
        <div class="text">
          <span class="icon-small-tip"></span>
          您还可查看 <i>{{lookNumber}}</i> 个项目的已签约品牌
        </div>
        <div class="btn">
          <span @click="lookBrandDetail()">查看已签约品牌</span>
        </div>
      </div>
      <div
        v-else-if="interestsData.totalsignednum > 0"
        class="look-detail-wrap"
      >
        <div class="text">
          <span class="icon-small-tip"></span>
          您还可查看 <i>{{lookNumber}}</i> 个项目的已签约品牌
        </div>
      </div>
      <div
        v-else
        class="noData-look-brand"
      >
        <img
          src="@/assets/images/public/not-plandata.png"
          alt=""
        >
        <p>抱歉，您无查看权限。</p>
      </div>
    </div>
    <div
      v-if="isShowBrandDetail && !analysisLoad"
      class="analysis"
    >
      <div class="charts-bottom">
        <div
          class="charts-left"
          id="charts-left"
        >
          <div class="title">已签约品牌统计<span> {{tip ? '(' + tip + ')' : ''}}</span></div>
          <div class="selectBtn-view">
            <div class="type-item mr-60 mt-24">
              统计维度：
              <span
                v-for="(item,index) in tabsListLeft"
                :key="index"
                @click="setIndicator(0, index)"
                :class="[tabsLeftValue === index ? 'active': '']"
              >{{item}}</span>
            </div>
            <div
              v-if="tabsListRight.length > 0"
              class="type-item mr-60 mt-24"
            >
              统计指标：
              <template v-for="(item,index) in tabsListRight">
                <span
                  :key="index"
                  @click="setIndicator(1, index)"
                  :class="[tabsRightValue === index ? 'active': '']"
                  v-if="(item !== '新开品牌' && item !== '留存3年以上品牌') || isRealData"
                >{{item}}</span>
              </template>
            </div>
          </div>
          <div class="breadcrumb">
            <span @click="toBack(-1)">{{tabsLeftValue === 0 ? '全部业态' : '全部楼层'}}</span>
            <span v-if="industyNameArr[0]">></span>
            <span
              v-if="industyNameArr[0]"
              @click="toBack(0)"
            >{{industyNameArr[0]}}</span>
            <span v-if="industyNameArr[1]">></span>
            <span
              v-if="industyNameArr[1]"
              @click="toBack(1)"
            >{{industyNameArr[1]}}</span>
          </div>
          <div
            class="chart-wrap"
            v-loading="loadBar"
          >
            <div v-if="signedData.labels.length > 0">
              <div
                class="signed-chart"
                id="signedChart"
              >
              </div>
              <div
                v-if="!isRealData"
                class="reference"
              >
                <img src="@/assets/images/reference.png">
              </div>
            </div>
            <div
              v-if="!loadBar && signedData.labels.length === 0"
              class="no-data"
            >
              <img src="@/assets/images/public/not-plandata.png">
              <p>暂无相关数据</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="detailedList"
        v-loading="loadList"
      >
        <div class="head">
          <div class="title">{{tabsListRight[tabsRightValue] ? tabsListRight[tabsRightValue] : '-'}}清单</div>
          <div class="selectBtn-view">
            <div class="type-item">
              <span
                v-for="(item, index) in tabList"
                :key="index"
                @click="tabSelect(index)"
                :class="[tabVal === index ? 'active': '']"
              >{{item}}</span>
            </div>
          </div>
        </div>
        <div class="list-wrap">
          <div v-if="showIndustyList.length > 0">
            <div
              v-for="(item, index) in showIndustyList"
              :key="index"
              class="row-wrap"
            >
              <div class="row1">{{item.value}}:</div>
              <!-- 有子业态 -->
              <template v-if="item.children && item.children.length > 0">
                <div
                  class="row2"
                  v-for="(item2, key) in item.children"
                  :key="key"
                >
                  <div class="col1">{{item2.value}}:</div>
                  <div class="col2">
                    <div
                      v-for="(item3, val) in item2.brands"
                      :key="val"
                      class="brand"
                      :class="{'isSourceBrand': item3.isSourceBrands}"
                      @click="toProjectDetails(item3.brandId, item3.brandName, item3.gbid)"
                    >
                      {{item3.brandName + '[' + item3.floor + ']'}}
                      <span v-if="item3.isNew && isRealData">新</span>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 无子业态，直接显示品牌 -->
              <template v-else>
                <div class="row2">
                  <div class="col1"></div>
                  <div class="col2 mt-35">
                    <div
                      v-for="(obj, val) in item.brands"
                      :key="val"
                      class="brand"
                      :class="{'isSourceBrand': obj.isSourceBrands}"
                      @click="toProjectDetails(obj.brandId, obj.brandName, obj.gbid)"
                    >
                      {{obj.brandName + '[' + obj.floor + ']'}}
                      <span v-if="obj.isNew && isRealData">新</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="!loadList && showIndustyList.length === 0"
            class="no-data"
          >
            <img src="@/assets/images/public/not-plandata.png">
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      tabsLeftValue: 0,
      tabsRightValue: 0,
      tabsListLeft: ['按业态', '按楼层'], // 统计维度
      tabsListRight: [], // 统计指标
      allChartData: [], // 图表数据（所有）
      loadBar: true,
      industyNameArr: [], // 面包屑（业态名称）
      signedData: {
        labels: [],
        series1: [],
        series2: []
      },
      tabVal: 0,
      tabList: [], // 品牌清单的业态
      industyList: [], // 品牌清单数据（所有）
      showIndustyList: [], // 品牌清单展示数据（展示）
      tempList: [], // 临时存放数据用
      loadList: true,
      analysisLoad: true,
      tip: '',
      isShowBrandDetail: false,
      lookNumber: 0,
      interestsData: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  props: {
    // 数据来源（true为现场采集数据，false为非现场采集数据（显示水印））
    isRealData: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.interestsData = JSON.parse(localStorage.getItem('wisdom_interests'))
    this.$nextTick(() => {
      this.axios.post(api.getProjectDetailLookNum).then((res) => {
        this.lookNumber = res.data.data
      })
      this.axios.post(api.getProjectDetailIsShow, { projectId: this.$route.query.id }).then((res) => {
        this.isShowBrandDetail = res.data.data
        this.analysisLoad = false
        if (this.isShowBrandDetail) {
          this.init()
        }
      }, rej => {
        console.log(rej)
        this.analysisLoad = false
      }).catch(err => {
        console.log(err)
        this.analysisLoad = false
      })
    })
  },
  methods: {
    lookBrandDetail () {
      this.axios.post(api.getProjectLookRightDeduction,
        {
          projectId: this.$route.query.id
        }
      )
        .then((res) => {
          this.analysisLoad = true
          if (res.data.data === 1) {
            this.isShowBrandDetail = true
            this.init()
          }
        })
    },
    setIndicator (key, value) {
      if (key === 0) { // 统计维度
        this.tabsLeftValue = value
        if (value === 1) {
          this.industyNameArr.length = 0
        }
        const industry = this.industyNameArr[this.industyNameArr.length - 1]
        this.getChart(this.tabsRightValue, industry)
      } else { // 统计指标
        this.tabsRightValue = value
        this.dealChartData(this.allChartData[value])
      }
      this.loadList = true
      this.tabVal = 0
      this.getList()
    },
    init () {
      this.axios.post(api.getProjectDetailsTip, { projectId: this.$route.query.id })
        .then((res) => {
          this.analysisLoad = false
          this.tip = res.data.data.tip + '数据更新：' + res.data.data.updateTime
        })
      // 获取图表数据
      this.getChart(0)
      // 获取清单数据
      this.getList()
    },
    // 获取图表数据
    getChart (key, industry) {
      const params = {
        projectId: this.$route.query.id,
        type: this.tabsLeftValue,
        industry: industry
      }
      this.axios.get(api.getSignedBrandChart, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.loadBar = false
          const Arr = res.data.data
          this.allChartData = Arr
          this.tabsListRight = Arr.map(item => {
            return item.name
          })
          // 初始化图表
          this.dealChartData(Arr[key])
        }
      }, rej => {
        console.log(rej)
        this.loadBar = false
      }).catch(err => {
        console.log(err)
        this.loadBar = false
      })
    },
    dealChartData (resData) {
      const obj = {
        labels: [],
        series1: [],
        series2: []
      }
      obj.labels = resData.children.map(item => {
        return item.name
      })
      obj.series1 = resData.children.map(item => {
        return item.brandNum
      })
      obj.series2 = resData.children.map(item => {
        return item.proportion
      })
      this.signedData = obj
      this.$nextTick(() => {
        this.creatChart('signedChart', this.signedData)
      })
    },
    toBack (key) {
      switch (key) {
        case -1:
          this.industyNameArr.length = 0
          this.getChart(this.tabsRightValue)
          break
        case 0:
          this.industyNameArr.pop()
          this.getChart(this.tabsRightValue, this.industyNameArr[0])
          break
        case 1:
          this.getChart(this.tabsRightValue, this.industyNameArr[1])
          break
      }
    },
    tabSelect (index) {
      this.tabVal = index
      this.showList(index)
    },
    // 获取清单数据
    getList () {
      this.industyList = []
      const params = {
        projectId: Number(this.$route.query.id),
        type: this.tabsLeftValue,
        id: this.tabsRightValue
      }
      this.axios.get(api.getSignedBrandList, { params: params }).then(res => {
        if (res.data.code === 0) {
          const Arr = res.data.data
          this.tabList = Arr.map(item => {
            return item.value
          })
          this.loadList = false
          this.industyList = Arr.map(item => {
            return item
          })
          this.showList(this.tabVal)
        }
      }, rej => {
        console.log(rej)
        this.loadList = false
      }).catch(err => {
        this.loadList = false
        console.log(err)
      })
    },
    showList (key) { // 展示清单数据
      this.showIndustyList = []
      if (this.industyList[key].children && this.industyList[key].children.length > 0) { // 有子业态
        this.showIndustyList = this.industyList[key].children.map(item => {
          return item
        })
      } else if (this.industyList[key].brands && this.industyList[key].brands.length > 0) { // 无子业态
        this.showIndustyList.push(this.industyList[key])
      }
      // console.log('this.showIndustyList', this.showIndustyList)
    },
    toProjectDetails: function (id, brandName, gbId) {
      if (gbId && gbId !== 0) {
        const brandDetailsHref = this.$router.resolve({
          path: '/brandDetail',
          query: {
            id: id,
            gbid: gbId,
            brandName: brandName,
            source: this.$route.query.source
          }
        })
        window.open(brandDetailsHref.href, '_blank')
      }
    },
    creatChart (box, resData) {
      // 双y轴实现从0值开始左右刻度线一致。设置坐标轴分割间隔, 最大值/分割段数
      const max1 = Math.max(...resData.series1) ? Math.max(...resData.series1) : 0
      const intervalValue1 = max1 / 5 ? max1 / 5 : null
      const max2 = Math.max(...resData.series2) ? Math.max(...resData.series2) : 0
      const intervalValue2 = max2 / 5 ? max2 / 5 : null
      const myChart = echarts.init(document.getElementById(box))
      const option = {
        color: '#3F8CFF',
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let html = '<span style="color: #fff;">' + params[0].name + '</span><br>'
            for (let i = 0; i < params.length; i++) {
              html += '<span style="color: #808191;">' + params[i].marker + (i === 0 ? '品牌数量: ' : '数量占比: ') + '</span>' + '<span style="color: #fff; font-weight: bold">' + (params[i].value || params[i].value === 0 ? params[i].value : '-') + (i === 1 ? '%' : '个') + '</span><br>'
            }
            return html
          }
        },
        grid: {
          top: '10%',
          right: '5%',
          left: '5%',
          bottom: '8%',
          containLable: true
        },
        xAxis: {
          type: 'category',
          color: '#59588D',
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          data: resData.labels
        },
        yAxis: [{
          name: '品牌数量/个',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#999999'
          },
          min: 0,
          max: max1,
          splitNumber: 5,
          interval: intervalValue1,
          axisLabel: {
            formatter: function (params) {
              return params.toFixed(2)
            },
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'dashed'
            }
          }
        }, {
          name: '数量占比/%',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#999999'
          },
          min: 0,
          max: max2,
          splitNumber: 5,
          interval: intervalValue2,
          axisLabel: {
            formatter: function (params) {
              return params.toFixed(2) + '%'
            },
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'dashed'
            }
          }
        }],
        series: [
          {
            type: 'bar',
            data: resData.series1,
            barWidth: '24',
            itemStyle: {
              color: '#00D7D5'
            },
            yAxisIndex: 0
          }, {
            type: 'line',
            data: resData.series2,
            itemStyle: {
              color: '#F89407'
            },
            symbol: 'none',
            yAxisIndex: 1
          }
        ]
      }
      myChart.setOption(option)
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          myChart.resize()
        })
      })
      const that = this
      myChart.off('click')
      myChart.on('click', function (params) {
        let Arr = []
        if (that.industyNameArr.length === 0) {
          that.tempList = that.industyList.filter(item => {
            return params.name === item.value
          })
          Arr = that.tempList
        } else if (that.industyNameArr.length === 1) {
          Arr = that.tempList[0].children.filter(item => {
            return params.name === item.value
          })
        }
        if (that.tabsLeftValue === 0 && params.name !== '未知业态' && Arr[0].children.length > 0 && that.industyNameArr.length < 2) {
          const Arr = that.allChartData[that.tabsRightValue].children.filter(item => {
            return item.name === params.name
          })
          that.industyNameArr.push(Arr[0].name)
          that.getChart(that.tabsRightValue, Arr[0].name)
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.analysis-wrap
  min-height 400px
.analysis
  width 1280px
  padding 30px 0 20px 0
  background-color #272930
  .charts-bottom
    padding 0 20px
    .title
      color #ffffff
      font-size 16px
      border-left 2px solid #ffa134
      padding-left 10px
      span
        color #999
        font-size 12px
    .charts-left
      width 100%
      .mr-60
        margin-right 60px
      .mt-24
        margin-top 24px
      .breadcrumb
        display flex
        margin 32px 0 32px 12px
        span
          display inline-block
          font-size 14px
          font-weight 400
          color #999
          &:nth-child(2n - 1)
            cursor pointer
          &:nth-child(2n)
            margin 0 8px
          &:last-child
            color #FFF
            cursor unset
      .chart-wrap
        min-height 200px
        .signed-chart
          width 1240px
          height 493px
          z-index 10
        .no-data
          padding 50px 0
        .reference
          width 1240px
          position absolute
          margin-top -400px
          text-align center
          img
            width 900px
  .detailedList
    margin 28px 32px auto 32px
    padding 16px 16px 0 24px
    background #1E1E23
    .head
      display flex
      align-items center
      justify-content space-between
      .title
        color #ffffff
        font-size 16px
        margin-top 20px
        span
          color #999
          font-size 12px
    .list-wrap
      margin-top 28px
      min-height 100px
      .row-wrap
        margin-bottom 48px
        &:last-child
          margin 0
      .row1
        font-size 14px
        font-weight 400
        color #fff
      .row2
        margin-top 16px
        display flex
        .col1
          min-width 120px
          font-size 14px
          font-weight 400
          color #999
        .col2
          display flex
          flex-wrap wrap
          margin-left 40px
          font-size 14px
          font-weight 400
          .brand
            color #999
            margin 0 20px 10px 0
            cursor pointer
            span
              display inline-block
              text-align center
              width 16px
              height 16px
              background #FF754C
              border-radius 2px
              font-size 10px
              font-weight 400
              color #FFF
          .isSourceBrand
            color #fff
      .mt-35
        margin-top -35px
.selectBtn-view
  padding-left 12px
  line-height 30px
  display flex
  flex-flow wrap
  .type-item
    // margin-top 24px
    display flex
    font-size 14px
    font-weight 400
    color #E5E5E5
    span
      padding 0 12px
      border 1px solid #979797
      display inline-block
      height 30px
      line-height 30px
      font-size 14px
      font-weight 500
      color #979797
      text-align center
      margin-right -1px
      cursor pointer
      &.active
        border-color #FFA134
        height 30px
        line-height 30px
        background #FFA134
        color #fff
.analysis-wrap-tips
  height 400px
  background-color #282A30
  text-align center
.look-detail-wrap
  padding-top 120px
  .text
    width 380px
    height 38px
    line-height 38px
    font-size 14px
    margin auto
    background-color rgba(0, 0, 0, 0.1)
    color #fff
    i
      color #ffa134
    .icon-small-tip:before
      color #fff
      vertical-align middle
  .btn
    margin-top 35px
    span
      display inline-block
      width 114px
      height 28px
      background-color #ffa134
      color #fff
      border-radius 3px
      font-size 14px
      line-height 28px
      cursor pointer
.noData-look-brand
  img
    padding-top 120px
  p
    color #fff
    font-size 12px
    font-weight 500
    margin-top 30px
</style>
