<template>
  <div class="demand">
    <div class="demand-content">
      <!-- <div class="region">
        <div class="region-head" v-if="projectDetails.ytzs1List.length > 0">招商需求</div>
        <div class="region-list" v-if="projectDetails.ytzs1List.length > 0">
          <ul>
            <li
            :class="{active:currentTab === index}"
            :key="index"
            :index="index"
            v-for="(item, index) in projectDetails.ytzs1List"
            @click="toRegion(item.ytzs1ID, $event ,index)">{{item.ytzs1Name}}</li>
          </ul>
        </div>
        <div class="city-list">
          <span
          :key="index"
          :index="index"
          v-for="(item, index) in cityList">{{item.ytzs2Name}}</span>
        </div>
      </div> -->
      <div class="region">
        <div class="region-head">项目地图</div>
        <div style="width:100%;height: 700px; margin:20px auto;" id="promap"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
export default {
  data () {
    return {
      currentTab: 0,
      IndoorMap: null
    }
  },
  props: [
    'projectDetails',
    'cityList'
  ],
  created () {
    // console.log(this.projectDetails)
  },
  mounted () {
    let marker = ''
    // let amap
    const toolBar = new AMap.ToolBar({
      visible: true
    })
    // console.log(toolBar)
    // AMap.plugin(['AMap.IndoorMap'], function () {
    //   indoorMap = new AMap.IndoorMap({ alwaysShow: true })
    //   // 设定在没有矢量底图的时候也显示，默认情况下室内图仅在有矢量底图的时候显示
    //   amap = new AMap.Map('promap', {
    //     resizeEnable: true,
    //     zoom: 17,
    //     // ToolBar: true,
    //     showIndoorMap: false, // 隐藏地图自带的室内地图图层
    //     layers: [indoorMap, new AMap.TileLayer()]
    //   })
    // })
    // this.IndoorMap = new AMap.IndoorMap('promap', {
    //   alwaysShow: true
    // })
    const map = new AMap.Map('promap', {
      center: [this.projectDetails.gdLng, this.projectDetails.gdLat],
      resizeEnable: true, // 自适应大小
      zoom: 17
    })

    marker = new AMap.Marker({
      icon: 'https://jscss.winshangdata.com/1.0/images/myicon3.png',
      position: [this.projectDetails.gdLng, this.projectDetails.gdLat]
    })
    map.addControl(toolBar)
    marker.setMap(map)
  },
  methods: {
    toRegion (rid, event, index) {
      this.$emit('regionId', index)
      this.currentTab = index
    }
  }
}
</script>

<style lang="stylus" scoped>
.demand
  width 1280px
  margin 0px auto
  padding 30px 0 30px 0
  background-color #272930
  .demand-head
    border-left 2px solid #FFA134
    padding-left 10px
    margin-left 20px
    color #fff
  .demand-content
    padding 0px 20px
    .region
      margin-bottom 30px
      .region-head
        width 100%
        color #e5e5e5
        font-size 16px
        border-left 2px solid #FFA134
        padding-left 10px
      .region-list
        margin-top 35px
        height 50px
        li
          display inline-block
          width 82px
          height 30px
          text-align center
          line-height 30px
          border 1px solid #979797
          color #979797
          margin-right 15px
          cursor pointer
          font-size 14px
        .active
          border 1px solid #ffa134
          color #ffa134
        li:hover
          border 1px solid #ffa134
          color #ffa134
      .city-list
        span
          margin-right 10px
          color #ccc
          text-align left
          font-size 13px
    .property
      margin-top 20px
      .property-head
        width 100%
        border-left 2px solid #ffa134
        padding-left 10px
        color #e5e5e5
        font-size 16px
       i
        font-style normal
      .property-main
        margin-top 40px
        line-height 40px
        color #ccc
        font-size 14px
    .other
      margin-top 20px
      .other-head
        width 100%
        border-bottom 2px solid #454a54
        color #e5e5e5
        font-size 16px
        line-height 42px
        background-color #323a48
        padding-left 10px
      .other-content
        margin-top 20px
        line-height 36px
        color #fff
        font-size 14px
</style>
