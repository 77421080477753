<template>
  <div
    class="analysis-wrap"
    v-loading="analysisLoad"
    element-loading-text="加载中..."
  >
    <div
      v-if="!isShowBrandDetail && !analysisLoad"
      class="analysis-wrap-tips"
    >
      <div
        v-if="lookNumber > 0"
        class="look-detail-wrap"
      >
        <div class="text">
          <span class="icon-small-tip"></span>
          您还可查看 <i>{{lookNumber}}</i> 个项目的项目分析
        </div>
        <div class="btn">
          <span @click="lookBrandDetail()">查看项目分析</span>
        </div>
      </div>
      <div
        v-else-if="interestsData.totalsignednum > 0"
        class="look-detail-wrap"
      >
        <div class="text">
          <span class="icon-small-tip"></span>
          您还可查看 <i>{{lookNumber}}</i> 个项目的项目分析
        </div>
      </div>
      <div
        v-else
        class="noData-look-brand"
      >
        <img
          src="@/assets/images/public/not-plandata.png"
          alt=""
        >
        <p>抱歉，您无查看权限。</p>
      </div>
    </div>
    <div
      v-if="isShowBrandDetail && !analysisLoad"
      class="analysis"
    >
      <div class="analysis-container">
        <div class="title">品牌标签画像</div>
        <div
          class="cloudWord-wrap"
          v-loading="loadWordCloud"
        >
          <WordCloud
            v-if="wordData.length > 0"
            :data="wordData"
            style="width: 100%; height: 334px; background: #1E1E23"
            :rotate="{from: 0, to: 0, numOfOrientation: 0 }"
            :fontSize="[14, 28]"
            :showTooltip="false"
            font="Arial"
            :color="['#F79306', '#00D7D5', '#37ABFD', '#10DD71']"
          ></WordCloud>
          <div
            v-if="!loadWordCloud && wordData.length === 0"
            class="no-data"
          >
            <img src="@/assets/images/public/not-plandata.png">
            <p>暂无相关数据</p>
          </div>
        </div>
        <div class="pie-wrap">
          <div
            v-for="(item, index) in pieData"
            :key="index"
            class="pie-chart"
            v-loading="item.loading"
          >
            <div class="title">{{item.title}}</div>
            <div
              v-if="item.data.length > 0"
              class="pie-box"
              :id="item.chartId"
            >
            </div>
            <div
              v-if="!item.loading && item.data.length === 0"
              class="no-data"
            >
              <img src="@/assets/images/public/not-plandata.png">
              <p>暂无相关数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="analysis-container mt-48">
        <div class="histogram-wrap">
          <div class="histogram-chart">
            <div class="title">业态客群消费能力</div>
            <div
              class="bar-wrap"
              v-loading="loadYeTai"
            >
              <div
                v-if="yetaiData.length > 0"
                class="single-bar"
                id="ytChart"
              ></div>
              <div
                v-if="!loadYeTai && yetaiData.length === 0"
                class="no-data"
              >
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </div>
          <div class="histogram-chart">
            <div class="title">楼层客群消费能力</div>
            <div
              class="bar-wrap"
              v-loading="loadFloor"
            >
              <div
                v-if="floorData.length > 0"
                class="single-bar"
                id="lcChart"
              ></div>
              <div
                v-if="!loadFloor && floorData.length === 0"
                class="no-data"
              >
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="analysis-container mt-48">
        <div class="flex-wrap">
          <div class="title">项目开关店分析</div>
          <div class="btns">
            <span
              @click="selectFunc(0)"
              :class="[selectValue === 0 ? 'active': '']"
            >按业态</span>
            <span
              @click="selectFunc(1)"
              :class="[selectValue === 1 ? 'active': '']"
            >按楼层</span>
          </div>
        </div>
        <div class="histogram-wrap">
          <div
            class="bar-wrap"
            v-loading="loadOpenCloseStore"
          >
            <div
              v-if="openCloseStoreData.length > 0"
              class="double-bar"
              id="openStorechart0"
            >
            </div>
            <div
              v-if="!loadOpenCloseStore && openCloseStoreData.length === 0"
              class="no-data"
            >
              <img src="@/assets/images/public/not-plandata.png">
              <p>暂无相关数据</p>
            </div>
          </div>
          <div
            class="bar-wrap"
            v-loading="loadYeTaiOpen"
          >
            <div
              v-show="yeTaiOpenData.length > 0"
              class="double-bar"
              id="openStorechart1"
            >
            </div>
            <div
              v-show="!loadYeTaiOpen && yeTaiOpenData.length === 0"
              class="no-data"
            >
              <img src="@/assets/images/public/not-plandata.png">
              <p>暂无相关数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import api from '@/api'
import { mapGetters } from 'vuex'
import WordCloud from 'vue-wordcloud'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      analysisLoad: true,
      isShowBrandDetail: false,
      lookNumber: 0,
      interestsData: {},
      wordData: [],
      loadWordCloud: true,
      pieData: [{
        title: '业态面积占比',
        chartId: 'pie0',
        loading: true,
        data: []
      }, {
        title: '品牌档次占比',
        chartId: 'pie1',
        loading: true,
        data: []
      }, {
        title: '品牌级次占比',
        chartId: 'pie2',
        loading: true,
        data: []
      }, {
        title: '新兴品牌占比',
        chartId: 'pie3',
        loading: true,
        data: []
      }, {
        title: '连锁品牌占比',
        chartId: 'pie4',
        loading: true,
        data: []
      }, {
        title: '品牌国籍占比',
        chartId: 'pie5',
        loading: true,
        data: []
      }],
      yetaiData: [], // 业态客群消费能力
      loadYeTai: true,
      floorData: [], // 楼层客群消费能力
      loadFloor: true,
      openCloseStoreData: [], // 开关店图表数据
      loadOpenCloseStore: true,
      yeTaiOpenData: [], // 半年内各业态或楼层开关数量
      loadYeTaiOpen: true,
      selectValue: 0,
      requestFlag: 0,
      startTime: '',
      endTime: ''
    }
  },
  components: {
    WordCloud
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.interestsData = JSON.parse(localStorage.getItem('wisdom_interests'))
    this.$nextTick(() => {
      this.axios.post(api.getProjectDetailLookNum).then((res) => {
        this.lookNumber = res.data.data
      })
      this.axios.post(api.getProjectDetailIsShow, { projectId: this.$route.query.id }).then((res) => {
        this.isShowBrandDetail = res.data.data
        this.analysisLoad = false
        if (this.isShowBrandDetail) {
          this.init()
        }
      }, rej => {
        console.log(rej)
        this.analysisLoad = false
      }).catch(err => {
        console.log(err)
        this.analysisLoad = false
      })
    })
  },
  methods: {
    lookBrandDetail () {
      this.axios.post(api.getProjectLookRightDeduction,
        {
          projectId: this.$route.query.id
        }
      )
        .then((res) => {
          this.analysisLoad = true
          if (res.data.data === 1) {
            this.isShowBrandDetail = true
            this.init()
          }
        })
    },
    init () {
      this.analysisLoad = false
      // 标签画像
      this.getBrandLabelPicture()
      // 业态面积占比
      this.getIndustrySignedAreaPie()
      // 品牌档次占比
      this.getBrandGradePie()
      // 品牌级次占比
      this.getBrandScalePie()
      // 新兴品牌占比
      this.getEmergingBrandPie()
      // 连锁品牌占比
      this.getChainBrandPie()
      // 品牌国籍占比
      this.getBrandNationalityPie()
      // 客群消费能力
      this.getYTpendingPower()
      this.getLCSpendingPower()
      // 项目开关店分析
      this.getprojectOpenCloseAnalyse()
      const year = Number(dayjs().format('YYYY'))
      const month = Number(dayjs().format('MM'))
      if (month > 7) {
        this.startTime = year + '-01-01'
        this.endTime = year + '-06-30'
      } else {
        this.startTime = year - 1 + '-07-01'
        this.endTime = year - 1 + '-12-31'
      }
      this.getprojectYeTaiAnalyse()
    },
    getBrandLabelPicture () { // 品牌标签画像
      this.axios.post(api.getBrandLabelPicture,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        this.loadWordCloud = false
        if (res.data.data.length > 0) {
          this.wordData = res.data.data
        }
      })
    },
    getIndustrySignedAreaPie () { // 业态面积占比
      this.axios.post(api.getIndustrySignedAreaPie,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.pieData[0].data = res.data.data
          this.pieData[0].loading = false
          this.$nextTick(() => {
            this.createPie('pie0', res.data.data)
          })
        }
      }, rej => {
        console.log(rej)
        this.pieData[0].loading = false
      }).catch(err => {
        console.log(err)
        this.pieData[0].loading = false
      })
    },
    getBrandGradePie () { // 品牌档次占比
      this.axios.post(api.getBrandGradePie,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.pieData[1].data = res.data.data.reverse()
          this.pieData[1].loading = false
          this.$nextTick(() => {
            this.createPie('pie1', res.data.data)
          })
        }
      }, rej => {
        console.log(rej)
        this.pieData[1].loading = false
      }).catch(err => {
        console.log(err)
        this.pieData[1].loading = false
      })
    },
    getBrandScalePie () { // 品牌级次占比
      this.axios.post(api.getBrandScalePie,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.pieData[2].data = res.data.data
          this.pieData[2].loading = false
          this.$nextTick(() => {
            this.createPie('pie2', res.data.data)
          })
        }
      }, rej => {
        console.log(rej)
        this.pieData[2].loading = false
      }).catch(err => {
        console.log(err)
        this.pieData[2].loading = false
      })
    },
    getEmergingBrandPie () { // 新兴品牌占比
      this.axios.post(api.getEmergingBrandPie,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.pieData[3].data = res.data.data.reverse()
          this.pieData[3].loading = false
          this.$nextTick(() => {
            this.createPie('pie3', res.data.data)
          })
        }
      }, rej => {
        console.log(rej)
        this.pieData[3].loading = false
      }).catch(err => {
        console.log(err)
        this.pieData[3].loading = false
      })
    },
    getChainBrandPie () { // 连锁品牌占比
      this.axios.post(api.getChainBrandPie,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.pieData[4].data = res.data.data
          this.pieData[4].loading = false
          this.$nextTick(() => {
            this.createPie('pie4', res.data.data)
          })
        }
      }, rej => {
        console.log(rej)
        this.pieData[4].loading = false
      }).catch(err => {
        console.log(err)
        this.pieData[4].loading = false
      })
    },
    getBrandNationalityPie () { // 品牌国籍占比
      this.axios.post(api.getBrandNationalityPie,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.pieData[5].data = res.data.data
          this.pieData[5].loading = false
          this.$nextTick(() => {
            this.createPie('pie5', res.data.data)
          })
        }
      }, rej => {
        console.log(rej)
        this.pieData[5].loading = false
      }).catch(err => {
        console.log(err)
        this.pieData[5].loading = false
      })
    },
    getYTpendingPower () { // 业态客群消费能力
      this.axios.post(api.getprojectSpendingPower,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.yetaiData = res.data.data
          this.loadYeTai = false
          const obj = {
            labels: [],
            series: []
          }
          res.data.data.forEach(item => {
            obj.labels.push(item.yeTaiName)
            obj.series.push(item.avgPrice)
          })
          this.$nextTick(() => {
            this.creatBar('ytChart', obj)
          })
        }
      }, rej => {
        console.log(rej)
        this.loadYeTai = false
      }).catch(err => {
        console.log(err)
        this.loadYeTai = false
      })
    },
    getLCSpendingPower () { // 楼层客群消费能力
      this.axios.post(api.getprojectLouCengSpendingPower,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.floorData = res.data.data
          this.loadFloor = false
          const obj = {
            labels: [],
            series: []
          }
          res.data.data.forEach(item => {
            obj.labels.push(item.louCengName)
            obj.series.push(item.avgPrice)
          })
          this.$nextTick(() => {
            this.creatBar('lcChart', obj)
          })
        }
      }, rej => {
        console.log(rej)
        this.loadFloor = false
      }).catch(err => {
        console.log(err)
        this.loadFloor = false
      })
    },
    getprojectOpenCloseAnalyse () { // 项目开关店分析
      this.axios.post(api.getprojectOpenCloseAnalyse,
        {
          projectId: this.$route.query.id
        }
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.openCloseStoreData = res.data.data.reverse()
          this.loadOpenCloseStore = false
          const obj = {
            labels: [],
            series: [{
              data: []
            }, {
              data: []
            }]
          }
          res.data.data.forEach(item => {
            obj.labels.push(item.yearTime)
            obj.series[0].data.push(item.openCount)
            obj.series[1].data.push(item.closeCount)
          })
          this.$nextTick(() => {
            this.creatBar2('openStorechart0', obj)
          })
        }
      }, rej => {
        console.log(rej)
        this.loadOpenCloseStore = false
      }).catch(err => {
        console.log(err)
        this.loadOpenCloseStore = false
      })
    },
    getprojectYeTaiAnalyse () {
      const params = {
        endTime: this.endTime,
        requestFlag: this.requestFlag,
        startTime: this.startTime,
        projectId: this.$route.query.id
      }
      if (!this.selectValue) { // 半年内各业态开关数量
        this.axios.post(api.getprojectYeTaiAnalyse, params).then((res) => {
          this.yeTaiOpenData = JSON.parse(JSON.stringify(res.data.data.yeTaiDate))
          this.loadYeTaiOpen = false
          const obj = {
            yname: res.data.data.yearName,
            labels: [],
            series: [{
              data: []
            }, {
              data: []
            }]
          }
          res.data.data.yeTaiDate.forEach(item => {
            obj.labels.push(item.yeTaiName)
            obj.series[0].data.push(item.openCount)
            obj.series[1].data.push(item.closeCount)
          })
          this.$nextTick(() => {
            this.creatBar3('openStorechart1', obj)
          })
        }, rej => {
          console.log(rej)
          this.loadYeTaiOpen = false
        }).catch(err => {
          console.log(err)
          this.loadYeTaiOpen = false
        })
      } else { // 半年内各楼层开关数量
        this.axios.post(api.getprojectLouCengAnalyse, params).then((res) => {
          this.yeTaiOpenData = JSON.parse(JSON.stringify(res.data.data.louCengDate))
          this.loadYeTaiOpen = false
          const obj = {
            yname: res.data.data.yearName,
            labels: [],
            series: [{
              data: []
            }, {
              data: []
            }]
          }
          res.data.data.louCengDate.forEach(item => {
            obj.labels.push(item.louCengName)
            obj.series[0].data.push(item.openCount)
            obj.series[1].data.push(item.closeCount)
          })
          this.$nextTick(() => {
            this.creatBar3('openStorechart1', obj)
          })
        }, rej => {
          console.log(rej)
          this.loadYeTaiOpen = false
        }).catch(err => {
          console.log(err)
          this.loadYeTaiOpen = false
        })
      }
    },
    selectFunc (val) {
      this.selectValue = val
      this.getprojectYeTaiAnalyse()
    },
    createPie (box, resData) {
      const emphasisFormat = [
        '{a|{c}}',
        '{b|{b}}',
        '{d|{d}%}'
      ]
      const colorList = [
        '#37ABFD',
        '#10DD71',
        '#F89407',
        '#F968B0',
        '#00D7D5',
        '#B2B3BD'
      ]
      const seriesData = resData
      let maxItem = seriesData[0]
      for (let i = 0; i < seriesData.length; i++) {
        if (Number(maxItem.value) < Number(seriesData[i].value)) {
          maxItem = seriesData[i]
        }
        if (seriesData[i].name.indexOf('其他') !== -1) {
          seriesData[i].itemStyle = { color: '#B2B3BD' }
        }
      }
      let chart = null
      if (!box) {
        return
      }
      chart = echarts.init(document.getElementById(box))
      chart.setOption({
        tooltip: {
          show: false,
          trigger: 'item'
        },
        legend: {
          show: true,
          top: '200',
          icon: 'circle',
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            color: '#999'
          },
          left: 'center'
        },
        color: colorList,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['55', '75'],
            center: ['50%', '42%'],
            hoverOffset: 5,
            legendHoverLink: false, // 移入leged不显示在中间
            avoidLabelOverlap: true,
            label: {
              normal: {
                show: false,
                position: 'center', // 设置居中
                formatter: function (data) { // 设置圆饼图中间文字排版
                  return data.name + '\n' + data.value // 对应的名字和值
                },
                textStyle: {
                  fontSize: '0'
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                formatter: emphasisFormat.join('\n'),
                rich: {
                  a: {
                    fontSize: maxItem.value >= 100000 ? 18 : 24, // 数值超过10位数，调小字体
                    lineHeight: 32
                  },
                  b: {
                    color: 'rgba(255,255,255)',
                    fontSize: 14,
                    lineHeight: 18
                  },
                  d: {
                    color: 'rgba(255,255,255)',
                    fontSize: 14,
                    lineHeight: 18
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      })
      chart.dispatchAction({
        type: 'highlight',
        name: maxItem.name
      })
      let currentName = maxItem.name
      const validNumList = [] // 非零的项集合
      for (let i = 0; i < seriesData.length; i++) {
        const item = seriesData[i]
        if (item.value) {
          validNumList.push(item.name)
        }
      }
      let selectedList = []
      chart.on('legendselectchanged', params => {
        selectedList = []
        chart.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        for (const key in params.selected) {
          if (params.selected[key]) {
            selectedList.push(key)
          }
        }
        if (selectedList.length) {
          if (validNumList.length) {
            const validSelectedList = [] // legend中选中的非零的项集合
            for (let i = 0; i < validNumList.length; i++) {
              const item = validNumList[i]
              if (selectedList.includes(item)) {
                validSelectedList.push(item)
              }
            }
            if (validSelectedList.length) {
              currentName = validSelectedList[0]
            } else {
              currentName = selectedList[0]
            }
          } else {
            currentName = selectedList[0]
          }
          chart.dispatchAction({
            type: 'highlight',
            name: currentName
          })
        }
      })
      chart.on('mouseover', params => {
        chart.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        currentName = params.name
        chart.dispatchAction({
          type: 'highlight',
          name: params.name
        })
      })
      // 鼠标移出
      chart.on('mouseout', params => {
        chart.dispatchAction({ // 持续高亮
          type: 'highlight',
          name: currentName
        })
      })
    },
    // 柱状图
    creatBar (box, resData) {
      // console.log(box, resData)
      const myChart = echarts.init(document.getElementById(box))
      const option = {
        color: '#3F8CFF',
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: 12
          },
          formatter: function (params) {
            const html = '<span style="padding-left:16px; color: #fff;">' + params[0].name + '</span><br />' + '<span style="color: #808191;">' + params[0].marker + '平均客单: ' + '</span>' + '<span style="color: #fff; font-weight: bold">' + params[0].value + '元</span>'
            return html
          }
        },
        grid: {
          top: '10%',
          right: '3%',
          left: '8%',
          bottom: '12%',
          containLable: true
        },
        xAxis: {
          type: 'category',
          color: '#59588D',
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          data: resData.labels
        },
        yAxis: [{
          name: '单位(元)',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#999999'
          },
          min: 0,
          axisLabel: {
            formatter: '{value}',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'dashed'
            }
          }
        }],
        series: [
          {
            type: 'bar',
            data: resData.series,
            barWidth: '12'
          }
        ]
      }
      myChart.setOption(option)
    },
    // 双柱状图
    creatBar2 (box, resData) {
      // console.log(box, resData)
      const myChart = echarts.init(document.getElementById(box))
      const option = {
        color: ['#37ABFD', '#00D7D5'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: 12
          },
          formatter: function (params) {
            let html = '<span style="padding-left:16px; color:#fff">' + params[0].name + '</span><br>'
            for (let i = 0; i < params.length; i++) {
              html += '<span style="color: #808191;">' + params[i].marker + params[i].seriesName + ': ' + '</span>' + '<span style="color: #fff; font-weight: bold">' + params[i].value + '</span><br>'
            }
            return html
          }
        },
        legend: {
          show: true,
          bottom: '0%',
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle',
          textStyle: {
            color: '#999'
          }
        },
        grid: {
          top: '10%',
          right: '3%',
          left: '5%',
          bottom: '18%',
          containLable: true
        },
        xAxis: {
          type: 'category',
          color: '#59588D',
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          data: resData.labels
        },
        yAxis: [{
          min: 0,
          axisLabel: {
            formatter: '{value}',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'dashed'
            }
          }
        }],
        series: [
          {
            name: '新开',
            type: 'bar',
            barGap: 0,
            data: resData.series[0].data,
            barWidth: '20'
          }, {
            name: '新关',
            type: 'bar',
            data: resData.series[1].data,
            barWidth: '20'
          }
        ]
      }
      myChart.setOption(option)
      const that = this
      myChart.on('click', function (params) {
        that.requestFlag = 1
        const time = params.name
        const year = params.name.slice(0, 4)
        if (time.indexOf('上') > -1) {
          that.startTime = year + '-01-01'
          that.endTime = year + '-06-30'
        } else {
          that.startTime = year + '-07-01'
          that.endTime = year + '-12-31'
        }
        that.getprojectYeTaiAnalyse()
      })
    },
    // 横双柱状图
    creatBar3 (box, resData) {
      // console.log(box, resData)
      const myChart = echarts.init(document.getElementById(box))
      const option = {
        color: ['#37ABFD', '#00D7D5'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: 12
          },
          formatter: function (params) {
            let html = '<span style="padding-left:16px; color:#fff">' + params[0].name + '</span><br>'
            for (let i = 0; i < params.length; i++) {
              html += '<span style="color: #808191;">' + params[i].marker + params[i].seriesName + ': ' + '</span>' + '<span style="color: #fff; font-weight: bold">' + params[i].value + '</span><br>'
            }
            return html
          }
        },
        legend: {
          show: true,
          bottom: '0%',
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle',
          textStyle: {
            color: '#999999'
          }
        },
        grid: {
          top: '10%',
          right: '3%',
          left: this.selectValue ? '10%' : '15%',
          bottom: '18%',
          containLable: true
        },
        xAxis: {
          type: 'value',
          color: '#59588D',
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'dashed'
            }
          }
        },
        yAxis: [{
          name: resData.yname,
          type: 'category',
          nameRotate: '0.1',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#999'
          },
          min: 0,
          axisLabel: {
            formatter: '{value}',
            color: '#808191',
            textStyle: {
              fontSize: 12
            },
            interval: 0
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'dashed'
            }
          },
          data: resData.labels
        }],
        series: [
          {
            name: '新开',
            type: 'bar',
            barGap: 0,
            data: resData.series[0].data
          }, {
            name: '新关',
            type: 'bar',
            data: resData.series[1].data
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="stylus" scoped>
.analysis-wrap
  min-height 400px
.analysis
  width 1280px
  padding 30px 20px 20px 20px
  background-color #272930
  box-sizing border-box
  .analysis-container
    .title // 标题
      color #fff
      font-size 16px
      border-left 2px solid #ffa134
      padding-left 10px
    .btns
      height 30px
      span
        display inline-block
        border 1px solid #979797
        width 66px
        height 30px
        line-height 30px
        font-size 14px
        font-family PingFang SC
        font-weight 400
        color #fff
        text-align center
        cursor pointer
        &.active
          background #FFA134
          color #fff
          border 1px solid #FFA134
          height 30px
    .flex-wrap
      display flex
      align-items center
      justify-content space-between
    .cloudWord-wrap // 词云
      display flex
      align-items center
      justify-content center
      margin-top 24px
      width 100%
      height 334px
      border-radius 2px
    .pie-wrap // 圆环图
      display flex
      flex-wrap wrap
      justify-content space-between
      .pie-chart
        margin-top 24px
        width 389px
        height 279px
        background #3A3C42
        opacity 1
        .title
          margin 16px 0 0 12px
          font-size 16px
        .pie-box
          width 100%
          height 240px
        .no-data
          padding 50px 0 0 0
    .histogram-wrap // 柱状图
      display flex
      justify-content space-between
      .histogram-chart
        width 570px
        height 315px
      .single-bar
        margin-top 25px
        width 570px
        height 294px
      .double-bar
        margin-top 25px
        width 570px
        height 322px
      .bar-wrap
        width 570px
        min-height 330px
      .no-data
        width 570px
        min-height 200px
        padding 80px 0 0 0
  .mt-48
    margin-top 48px
.analysis-wrap-tips
  height 400px
  background-color #282A30
  text-align center
  .look-detail-wrap
    padding-top 120px
    .text
      width 380px
      height 38px
      line-height 38px
      font-size 14px
      margin auto
      background-color rgba(0, 0, 0, 0.1)
      color #fff
      i
        color #ffa134
      .icon-small-tip:before
        color #fff
        vertical-align middle
    .btn
      margin-top 35px
      span
        display inline-block
        width 114px
        height 28px
        background-color #ffa134
        color #fff
        border-radius 3px
        font-size 14px
        line-height 28px
        cursor pointer
  .noData-look-brand
    img
      padding-top 120px
    p
      color #fff
      font-size 12px
      font-weight 500
      margin-top 30px
</style>
