<template>
  <div
    class="details-wrap"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div v-if="!loading">
      <div class="crumbs">
        <!-- <span @click="toHome()">云端品牌库</span> > -->
        <!-- <span @click="toBrandLib()">项目库 ></span> -->
        <!-- <span v-if="source===2" @click="toMockRoute()">模拟招商 ></span> -->
        <!-- <span v-if="source===3" @click="toInvite()">{{user.openingStatus === 1 ? '招商' : '邀约'}}管理 ></span> -->
        <!-- {{projectDetails.projectName}} -->
      </div>
      <div class="details-content">
        <div class="details-head">
          <div class="brandInfo">
            <div class="brandName">
              {{projectDetails.projectName}}
              <span v-if="projectDetails.jingyingleileixing">奥特莱斯</span>
            </div>
            <div class="companyName">{{projectDetails.zongbuName}}</div>
          </div>
        </div>
        <div class="center-wrap">
          <div class="left">
            <div class="detail-big-image">
              <img
                v-if="slideImages[nowIndex]"
                :src="slideImages[nowIndex]"
                imgtype='goods'
              />
              <img
                v-else
                src="@/assets/images/public/notPic.jpg"
                imgtype='goods'
              />
            </div>
            <div class="detail-slider">
              <div class="detail-slider-images">
                <ul
                  id="detail-slider-list"
                  :style="{transitionDuration: duration + 's', transform: 'translateX(-' + position + 'px)' }"
                >
                  <li
                    :key="index"
                    v-for="(item, index) in slideImages"
                  >
                    <div
                      class="detail-slider-small-images"
                      :class="{ 'active': index === nowIndex }"
                      @click="selectImage(index)"
                    >
                      <img
                        :src="item"
                        imgtype='smallgoods'
                      />
                    </div>
                  </li>
                  <li v-if="slideImages.length <= 3">
                    <div class="detail-slider-small-images">
                      <img
                        src="@/assets/images/public/notPic.jpg"
                        imgtype='smallgoods'
                        alt=""
                      >
                    </div>
                  </li>
                  <li v-if="slideImages.length <= 2">
                    <div class="detail-slider-small-images">
                      <img
                        src="@/assets/images/public/notPic.jpg"
                        imgtype='smallgoods'
                        alt=""
                      >
                    </div>
                  </li>
                  <li v-if="slideImages.length <= 1">
                    <div class="detail-slider-small-images">
                      <img
                        src="@/assets/images/public/notPic.jpg"
                        imgtype='smallgoods'
                        alt=""
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="detail-slider-arrow">
                <div
                  class="detail-slider-prev"
                  @click="selectImage(prevIndex),prevImg()"
                ><span class="el-icon el-icon-arrow-left"></span></div>
                <div
                  class="detail-slider-next"
                  @click="selectImage(nextIndex),nextImg()"
                ><span class="el-icon el-icon-arrow-right"></span></div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="category">
              <div class="operation">
                <div class="cateName">{{projectDetails.xmZhuangTai===2072?"已开业" : "未开业"}}{{projectDetails.xmStatus && projectDetails.xmStatus !== '其他' ? '(' + projectDetails.xmStatus + ')': ''}}</div>
                <div class="type">开业状态</div>
              </div>
              <div class="state">
                <div class="cateName">{{projectDetails.isZhaoShang===1?'正在招商':'品牌调整'}}</div>
                <div class="type">招商状态</div>
              </div>
            </div>
            <div class="brandDetail">
              <ul class="brandDetail-left fl">
                <li><span>项目类型：</span>{{projectDetails.wuYeLx}}</li>
                <li><span>项目档次：</span>{{projectDetails.xiangmudangci ? projectDetails.xiangmudangci: '暂无'}}</li>
                <li><span>开业时间：</span>{{projectDetails.kaiYeShiJianReal ? projectDetails.kaiYeShiJianReal: '暂无'}}</li>
                <li><span>商业面积：</span>{{projectDetails.shangYeMianji !== 0 ? projectDetails.shangYeMianji + '万平米': '暂无'}}</li>
                <li><span>商业楼层：</span>{{(projectDetails.layerHeightMin !== 0 && projectDetails.layerHeightMax !== 0) ? projectDetails.layerHeightMin + '层到' + projectDetails.layerHeightMax + '层': '暂无'}}</li>
                <li><span>连锁项目：</span>{{projectDetails.isLianSuo===1?'是':'否'}}</li>
                <li><span>所在城市：</span>{{projectDetails.projectCityName}}</li>
                <li style="display: flex;"><span>项目地址：</span> <i
                    class="ellipsis2"
                    style="width: 500px;"
                  >{{projectDetails.projectCityName}}{{projectDetails.projectAreaName}}{{projectDetails.address}}</i> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="details-information"
        id="navTop"
        :class="searchBarFixed"
      >
        <div class="details-information-header">
          <ul>
            <li
              :class="[currentTab == 'Survey' ? 'active':'' ]"
              @click="toggleTab('Survey')"
            >
              项目概况
            </li>
            <li
              v-if="qyHide"
              :class="[currentTab == 'brandList' ? 'active':'' ]"
              @click="toggleTab('brandList')"
            >
              品牌清单
            </li>
            <!-- 非现场采集数据，不显示项目分析 -->
            <li
              v-if="qyHide && isRealData"
              :class="[currentTab == 'Analysis' ? 'active':'' ]"
              @click="toggleTab('Analysis')"
            >
              项目分析
            </li>
            <li
              :class="[currentTab == 'Demand' ? 'active':'' ]"
              @click="toggleTab('Demand')"
            >
              项目地图
            </li>
          </ul>
        </div>
      </div>
      <div
        class="tab-content"
        id="content"
      >
        <div v-if="currentTab">
          <prince
            v-bind="projectDetails"
            :projectDetails="projectDetails"
            :is="currentTab"
            :curRid="currentRid"
            :cityList="cityList"
            :projectList="projectList"
            :isRealData="isRealData"
            @regionId="onRegionId"
            @areaId="onAreaId"
            keep-alive
          ></prince>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import Demand from './Demand'
import Survey from './Survey'
import brandList from './brandList'
import Analysis from './Analysis'
export default {
  data () {
    return {
      projectDetails: {},
      slideImages: [],
      cityList: [],
      pileList: [],
      projectList: [],
      tagNameList: [],
      nowIndex: 0,
      divWidth: 60,
      duration: 0.5,
      position: 0,
      currentTab: 'Survey', // currentTab 用于标识当前触发的子组件
      currentRid: '',
      regionId: '',
      areaId: '',
      liWidth: -250,
      liNowIndex: 0,
      isLast: true,
      frist: false,
      DEFAULTCODE: 0,
      searchBarFixed: '',
      loading: true,
      qyHide: true,
      isRealData: false // true为现场采集数据，false为非现场采集数据
    }
  },
  components: {
    Demand,
    Survey,
    brandList,
    Analysis
  },
  computed: {
    ...mapGetters({
      uid: 'uid',
      user: 'user'
    }),
    prevIndex () {
      if (this.nowIndex === 0) {
        return 0
      } else {
        return this.nowIndex - 1
      }
    },
    nextIndex () {
      const arr = Object.keys(this.slideImages)
      const len = arr.length
      if (this.nowIndex === len - 1) {
        return this.nowIndex
      } else {
        return this.nowIndex + 1
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    this.getdetailsData()
    this.questionState = this.$route.query.questionState
    this.axios.post(api.getYiQianYueTou, { projectId: this.$route.query.id })
      .then((res) => {
        if (res.data.data > 0) {
          this.qyHide = true
        } else {
          this.qyHide = false
        }
      })
    const params = {
      projectId: this.$route.query.id
    }
    this.axios.get(api.getIsRealData, { params: params }).then(res => {
      if (res.data.code === 0) {
        console.log(res)
        this.isRealData = res.data.data
      }
    })
  },
  methods: {
    prevImg () {
      this.position = (this.nowIndex - 2) * (this.divWidth - 10)
    },
    nextImg () {
      if (this.slideImages.length > 3) {
        this.position = (this.nowIndex - 2) * (this.divWidth + 10)
      }
    },
    getdetailsData () {
      // 项目详情
      this.axios.post(api.getProjectDetails, { projectId: this.$route.query.id })
        .then((res) => {
          this.DEFAULTCODE = res.data.code
          this.projectDetails = res.data.data
          if (this.projectDetails.ytzs1List.length > 0) {
            this.cityList = this.projectDetails.ytzs1List[0].ytzs2List
          }
          this.loading = false
        })
      // 品牌图片列表
      this.axios.post(api.getProjectPicList, { projectId: this.$route.query.id })
        .then((res) => {
          this.slideImages = res.data.data
        })
    },
    onRegionId (rid) {
      this.regionId = rid
      this.getCityList(rid)
    },
    onAreaId (aid) {
      this.areaId = aid
    },
    selectImage (index) {
      const len = this.slideImages.length
      if (index > len - 1) {
        index = 0
      }
      this.nowIndex = index
      const nowIndex = this.nowIndex
      if (nowIndex <= 2) {
        this.position = 0
      } else if (nowIndex > 2 && nowIndex < len - 2) {
        this.position = (nowIndex - 2) * (this.divWidth + 120)
      }
    },
    stopSlide () {
      clearInterval(this.slide)
    },
    startSlide () {
      // this.slide = setInterval(() => {
      //   this.selectImage(this.nowIndex + 1)
      // }, 3000)
    },
    toggleTab: function (tab) {
      this.currentTab = tab
    },
    getCityList: function (index) {
      // 获取区域城市
      this.cityList = this.projectDetails.ytzs1List[index].ytzs2List
    },
    toDetails (id, questionState) {
      // let brandDetailsHref = this.$router.resolve({
      //   'path': '/BrandDetails',
      //   'query': {
      //     'id': id,
      //     'source': 4,
      //     'questionState': questionState
      //   }
      // })
      // window.open('/' + brandDetailsHref.href, '_blank')
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=4&questionState=' + questionState, '_blank')
    },
    prev () {
      if (this.liNowIndex === 0) {
        this.isLast = true
        return 0
      } else {
        this.liNowIndex--
        this.frist = false
      }
    },
    next () {
      if (this.liNowIndex <= 2) {
        this.liNowIndex++
        this.isLast = false
      } else {
        this.frist = true
        return false
      }
    },
    toMockRoute () {
      this.$router.push({
        path: '/MockRoute'
      })
    },
    toHome () {
      this.$router.push({
        path: '/'
      })
    },
    toInvite () {
      this.$router.push({
        path: '/InviteBrandList'
      })
    },
    toBrandLib () {
      // console.log(this.$route.query.source)
      this.$router.push({
        path: '/SelfBrandList',
        query: {
          source: this.$route.query.source
        }
      })
    },
    handleScroll (e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = document.querySelector('#navTop').offsetTop || 0
      const contentTop = document.querySelector('#content').offsetTop || 0
      if (scrollTop > offsetTop) {
        this.searchBarFixed = 'isFixed'
      }
      if (scrollTop < contentTop) {
        this.searchBarFixed = ''
      }
    }
  },
  mounted () {
    // this.$fullLoading.close()
    this.startSlide()
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="stylus" scoped>
.details-wrap
  width 1280px
  min-height 600px
  margin 0px auto
  position relative
  padding-bottom 24px
  .crumbs
    color #ccc
    font-size 14px
    span
      color #fff
      cursor pointer
.details-content
  width 1280px
  background-color #272930
  position relative
  margin-top 30px
  margin-bottom 15px
.details-head
  width 100%
  height 115px
  border-bottom 1px solid rgba(226, 226, 226, 0.1)
.brandLogo
  position absolute
  top 15px
  left 35px
  width 115px
  height 85px
.brandInfo
  padding-left 30px
  padding-top 30px
.claim
  position absolute
  top 35px
  right 100px
  width 160px
  height 40px
  line-height 40px
  text-align center
  border 1px solid #ccc
  font-size 16px
  cursor pointer
.brandName
  display flex
  align-items center
  font-size 24px
  color #fff
  span
    margin-left 8px
    display inline-block
    width 64px
    height 24px
    line-height 24px
    text-align center
    border 1px solid #F89407
    opacity 1
    border-radius 2px
    font-size 12px
    font-weight 400
    color #F89407
.companyName
  font-size 12px
  color #999
  margin-top 8px
.label-wrap
  display inline-block
  padding 0 8px
  height 25px
  line-height 25px
  margin-right 10px
  color #fff
  font-size 12px
  border 1px solid #BFBFBF
  border-radius 12px
  span
    margin-right 3px
.center-wrap
  height 510px
  padding 25px 60px 25px 35px
  box-sizing border-box
  .left
    float left
    .detail-big-image
      width 425px
      height 330px
      overflow hidden
      img
        width 425px
        height 318px
    .detail-slider
      width 435px
      height 100px
      position relative
      overflow hidden
      .detail-slider-arrow
        visibility hidden
      .detail-slider-images
        width 560px
        height 100px
        margin 0 auto
        overflow hidden
        #detail-slider-list
          width 999px
        li
          float left
          cursor pointer
          margin-right 12px
          .detail-slider-small-images
            width 130px
            height 96px
            overflow hidden
            border 2px solid #616770
            img
              width 100%
              height 100%
          .detail-slider-small-images.active
            border 2px solid #3585ff
    .detail-slider-prev
      position absolute
      top 0px
      left 0px
      width 20px
      height 100px
      cursor pointer
      background rgba(0, 0, 0, 0.7)
      color #fff
      line-height 95px
      text-align center
    .detail-slider-next
      position absolute
      top 0px
      right 0px
      width 20px
      height 100px
      cursor pointer
      background rgba(0, 0, 0, 0.7)
      color #fff
      line-height 95px
      text-align center
  .detail-slider:hover .detail-slider-arrow
    visibility visible
  .right
    float left
    margin-left 60px
    .category
      width 500px
      padding-bottom 30px
      .cateName
        font-size 18px
        color #fff
      .type
        font-size 14px
        color #ccc
        margin-top 5px
    .operation
      width 48%
      display inline-block
    .state
      width 48%
      display inline-block
    .brandDetail
      width 100%
      border-top 1px solid #7a7f86
      padding-bottom 40px
      padding-top 40px
      height 239px
      li
        height 42px
        line-height 42px
        font-size 16px
        color #fff
        span
          color #999
          margin-right 60px
    .lookContact
      width 100%
      padding 30px 0
      .contact
        width 160px
        height 40px
        line-height 40px
        text-align center
        color #FFA134
        border-radius 3px
        cursor pointer
        border 1px solid #FFA134
      .clickable
        width 160px
        height 40px
        line-height 40px
        text-align center
        background-color #6c717a
        color #ccc
        border-radius 3px
        cursor pointer
.updateTime
  margin-bottom 15px
  margin-right 20px
  color #FFA134
  position absolute
  right 20px
  top 30px
  cursor pointer
  .icon-edit:before
    color #FFA134
    margin-right 5px
  .time
    margin-top 20px
    font-size 12px
    color #999
  .edit-brand
    text-align right
.isFixed
  position fixed
  top 0px
  z-index 9999
  width 100%
  background-color #cccccc
  margin-top 0
.details-information
  width 1280px
  background-color #272930
  .pay
    padding 20px
    font-size 16px
    .pay-content
      line-height 50px
      border-radius 5px
      background-color #fff
      padding-left 15px
      span
        color #3585ff
  .details-information-header
    height 55px
    border-bottom 1px solid rgba(226, 226, 226, 0.1)
    li
      float left
      height 53px
      line-height 55px
      color #e5e5e5
      font-size 16px
      margin-left 20px
      margin-right 30px
      cursor pointer
    .active
      color #FFA134
      border-bottom 2px solid #FFA134
    li:hover
      color #FFA134
      border-bottom 2px solid #FFA134
.follow-wrap
  width 1280px
  margin-top 15px
  background-color #272930
  .follow-content
    padding 30px 15px
    .follow-head
      border-left 2px solid #FFA134
      padding-left 10px
      color #fff
    .flip
      float right
      cursor pointer
      position relative
      .el-icon-arrow-left
        padding 8px
        background-color #fff
        color #666
        margin-right 5px
      .el-icon-arrow-right
        padding 8px
        background-color #fff
        color #666
      .grey
        color #ccc
    .follow-list
      margin-top 10px
      overflow hidden
      position relative
    li
      display inline-block
      cursor pointer
      width 200px
      .follow-list-wrap
        width 200px
        height 170px
        overflow hidden
        img
          width 100%
          height 100%
      .brand-Name
        display inline-block
        font-size 16px
        margin 15px 0 7px 0
        color #ffffff
        width 200px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      .brand-deta
        color #aeb0b1
        font-size 14px
.list-item
  position relative
  width 200px
  height 194px
  background-color #fff
  margin 10px 10px 10px 0
  // padding 11px 9px 10px 10px
.list-item:nth-child(6n)
  margin-right 0
.item-image
  display inline-block
  width 55px
  height 53px
  background no-repeat center center
  background-size 100%
  margin-left 15px
  margin-top 10px
.item-info-xx
  display inline-block
  position absolute
  bottom 10px
  left 4.5px
  width 188px
  height 28px
  text-align center
  line-height 28px
  font-size 12px
  cursor pointer
  border 1px solid #eee
  border-radius 4px
  color #333
  background #fff
.item-info-need
  font-size 12px
  color #333
  margin-left 15px
  margin-top 10px
  p
    line-height 20px
.item-brandName
  position absolute
  top 15px
  left 80px
  font-weight 600
  width 120px
  height 33px
  overflow hidden
  text-overflow ellipsis
  -webkit-line-clamp 2
  -webkit-box-orient vertical
  font-size 14px
  color #333
</style>
